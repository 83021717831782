<template>
  <div class="home">
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '200px'" v-show="pc_mobile=='pc'">
        <div class="logo">
          <img src="../../src/assets/images/logo.svg" />
          <div v-show="!isCollapse">吉玩玩</div>
        </div>
        <el-menu
          :collapse-transition="false"
          :collapse="isCollapse"
          background-color="#001529"
          text-color="#fff"
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
          @select="handleSelect"
          router
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-goods"></i>
              <span>活动管理</span>
            </template>
            <!-- <el-menu-item index="1-1">代金券列表</el-menu-item> -->
            <el-menu-item index="/pay">买单券</el-menu-item>
            <el-menu-item index="/group">团购券</el-menu-item>
            <el-menu-item index="/city">消费券</el-menu-item>
            <!-- <el-menu-item index="/city">城市消费券</el-menu-item> -->
            <!-- <el-submenu index="1-4">
              <template slot="title">选项4</template>
            </el-submenu> -->
          </el-submenu>
          <el-menu-item index="/service">
            <i class="el-icon-s-custom"></i>
            <span slot="title">服务商管理</span>
          </el-menu-item>
          <el-menu-item index="/merchant">
            <i class="el-icon-user-solid"></i>
            <span slot="title">商户管理</span>
          </el-menu-item>
          
          <el-menu-item index="/shop">
            <i class="el-icon-s-shop"></i>
            <span slot="title">门店管理</span>
          </el-menu-item>
          <el-menu-item index="/classify">
            <i class="el-icon-s-opportunity"></i>
            <span slot="title">分类管理</span>
          </el-menu-item>
          <el-menu-item index="/banner">
            <i class="el-icon-picture"></i>
            <span slot="title">Banner管理</span>
          </el-menu-item>
          <el-menu-item index="/article">
            <i class="el-icon-date"></i>
            <span slot="title">文章管理</span> 
          </el-menu-item>
          <el-menu-item index="/hotgroup">
            <i class="el-icon-picture"></i>
            <span slot="title">甄选团购</span>
          </el-menu-item>
          <el-menu-item index="/order">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
          <el-menu-item index="/cooperation">
            <i class="el-icon-s-order"></i>
            <span slot="title">合作管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header>
          <div  v-show="pc_mobile=='mobile'">
            <i class="el-icon-s-unfold" style="font-size:24px" @click="dialogFormVisible=true"></i>
          </div>
          <div  v-show="pc_mobile=='pc'" @click="handisCollapse">
            <i class="el-icon-s-unfold" style="font-size:24px"></i>
          </div>
          <div style="cursor: pointer;">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
            <el-dropdown  @command="handleCommand">
              <span class="el-dropdown-link">
                zyx2021<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="退出登陆">退出登陆</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main :style="pc_mobile=='mobile'?'padding:0':''">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <el-drawer
            id="main-el-drawer"
            size="200px"
            :visible.sync="dialogFormVisible"
            direction="ltr"
            :show-close="false"
            >
            <div class="logo">
          <img src="../../src/assets/images/logo.svg" />
          <div>吉玩玩</div>
        </div>
        <el-menu
          background-color="#001529"
          text-color="#fff"
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
          @select="handleSelect"
          router
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-goods"></i>
              <span>活动管理</span>
            </template>
            <!-- <el-menu-item index="1-1">代金券列表</el-menu-item> -->
            <el-menu-item index="/pay">买单券</el-menu-item>
            <el-menu-item index="/group">团购券</el-menu-item>
            <!-- <el-menu-item index="/city">城市消费券</el-menu-item> -->
            <!-- <el-submenu index="1-4">
              <template slot="title">选项4</template>
            </el-submenu> -->
          </el-submenu>
          <el-menu-item index="service">
            <i class="el-icon-s-custom"></i>
            <span slot="title">服务商管理</span>
          </el-menu-item>
          <el-menu-item index="/merchant">
            <i class="el-icon-user-solid"></i>
            <span slot="title">商户管理</span>
          </el-menu-item>
          
          <el-menu-item index="/shop">
            <i class="el-icon-s-shop"></i>
            <span slot="title">门店管理</span>
          </el-menu-item>
          <el-menu-item index="classify">
            <i class="el-icon-s-opportunity"></i>
            <span slot="title">分类管理</span>
          </el-menu-item>
          <el-menu-item index="/banner">
            <i class="el-icon-picture"></i>
            <span slot="title">Banner管理</span>
          </el-menu-item>
          <el-menu-item index="/order">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
        </el-menu>
      
    </el-drawer>
</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      activeMenu:'/pay',
      dialogFormVisible:false,
      pc_mobile:'pc',
      isCollapse:false
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal){
        console.log(val)
        console.log(oldVal)
        sessionStorage.setItem('activeMenu', val.fullPath);
        this.activeMenu = val.fullPath
        // console.log(val);
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    if(window.screen.width>=430){
      this.pc_mobile = 'pc'
    }else{
      this.pc_mobile = 'mobile'
    }
    // 页面加载完成后，从localStorage中恢复选中状态
    this.activeMenu = localStorage.getItem('activeMenu') || '/pay';
  },
  methods:{
    handisCollapse() {
        this.isCollapse = !this.isCollapse;
    },
    handleSelect(key, keyPath) {
      // 菜单项选中时的回调，可以用来更新activeMenu的值
      this.activeMenu = key;
      // 将当前选中的菜单项保存到localStorage
      localStorage.setItem('activeMenu', key);
    },
    handleCommand(){
      localStorage.clear()
      this.$router.push('/login')
    },
  }
};
</script>

<style lang="less" scoped>
.home{
  height: 100vh;
}
.el-container{
  height: 100vh;
}
.logo {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0 auto;
  img {
    width: 32px;
    height: 32px;
    margin: 0 15px;
  }
  div {
    color: #fff;
    line-height: 60px;
  }
}
.el-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  color: #333;
  div{
    display: flex;
    align-items: center;
    height: 60px;
  }
}


.el-aside {
  background-color: rgba(0, 21, 41, 1);
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #f0f2f5;
  // text-align: center;
}
.el-menu-item{
  display: flex;
  align-items: center;
  // width: 200px;
}
/deep/ .el-submenu__title{
  display: flex;
  align-items: center;
  // width: 200px;
}
/deep/ .el-menu--inline{
  // width: 200px;
}
.el-menu{
  border: none;
}
.el-dropdown-menu{
  margin-top: 0 !important;
}
/deep/ #main-el-drawer .el-drawer__body{
  background-color: rgba(0, 21, 41, 1);
}
/deep/ #main-el-drawer .el-drawer__header{
  display: none !important;
}

</style>
