import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/pay'
  },
  {
    path: '/',
    name: 'main',
    component: Main,
    children:[
      {
        path: '/order',
        name: '订单管理',
        meta:{title:"订单管理"},
        component:()=>import('../views/order/order.vue'),
        children:[
          
        ]
      },
      {
        path: '/banner',
        name: 'banner管理',
        meta:{title:"banner管理"},
        component:()=>import('../views/banner/banner.vue'),
        children:[
          
        ]
      },
      {
        path: '/shop',
        name: '门店管理',
        meta:{title:"门店管理"},
        component:()=>import('../views/shop/shop.vue'),
        children:[
          
        ]
      },
      {
        path: '/article',
        name: '文章管理',
        meta:{title:"文章管理"},
        component:()=>import('../views/article/article.vue'),
        children:[
          
        ]
      },
      {
        path: '/addarticle',
        name: '文章管理',
        meta:{title:"文章管理"},
        component:()=>import('../views/article/addarticle.vue'),
        children:[
          
        ]
      },
      {
        path: '/merchant',
        name: '商户管理',
        meta:{title:"商户管理"},
        component:()=>import('../views/merchant/merchant.vue'),
        children:[
          
        ]
      },
      {
        path: '/service',
        name: '服务商管理',
        meta:{title:"服务商管理"},
        component:()=>import('../views/service/service.vue'),
        children:[
          
        ]
      },
      {
        path: '/classify',
        name: '分类管理',
        meta:{title:"分类管理"},
        component:()=>import('../views/classify/classify.vue'),
        children:[
          
        ]
      },
      {
        path: '/pay',
        name: '买单券管理',
        meta:{title:"买单券"},
        component:()=>import('../views/pay/pay.vue'),
        children:[
          
        ]
      },
      {
        path: '/addpay',
        name: '买单券管理',
        meta:{title:"买单券"},
        component:()=>import('../views/pay/addpay.vue'),
        children:[
          
        ]
      },
      {
        path: '/group',
        name: '团购券管理',
        meta:{title:"团购券"},
        component:()=>import('../views/group/group.vue'),
        children:[
          
        ]
      },
      {
        path: '/addgroup',
        name: '团购券管理',
        meta:{title:"团购券"},
        component:()=>import('../views/group/addgroup.vue'),
        children:[
          
        ]
      },
      {
        path: '/city',
        name: '消费券',
        meta:{title:"消费券"},
        component:()=>import('../views/city/city.vue'),
        children:[
          
        ]
      },
      {
        path: '/hotgroup',
        name: '甄选团购',
        meta:{title:"甄选团购"},
        component:()=>import('../views/hotgroup/hotgroup.vue'),
        children:[
          
        ]
      },
      {
        path: '/cooperation',
        name: '合作管理',
        meta:{title:"合作管理"},
        component:()=>import('../views/cooperation/cooperation.vue'),
        children:[
          
        ]
      },

      
      // {
      //   path: '/city',
      //   name: '城市消费券管理',
      //   component:()=>import('../views/city/city.vue'),
      //   children:[
      //   ]
      // },
      
    ]
  },
  {
    path: '/login',
    name: 'login',
    component:()=>import('../views/login/login.vue'),
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}
// router.beforeEach((to,from,next)=>{
//   // let user = localStorage.getItem("user");
//   if(to.path!=='/login'){
//       // if(!user){
//         console.log(1)
//         next()
//       // }else{
//       //   console.log(2)
//       //   next()
//       // }
//   }else{
//     console.log(3)
//       next()
//   }
//   // }else{
//       // next()
//   // }
// })


router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  localStorage.setItem('activeMenu', to.fullPath);
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})


export default router
